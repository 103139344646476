import $ from 'jquery';
import initModulesInScope from '@elements/init-modules-in-scope';
import {showNotification} from '@elements/alert-notification';
import 'url-search-params-polyfill'; // Edge Polyfill
import fetch from "@elements/fetch"

export function init(){
    /* account */
    let $accountLoading = $('.js-account-list__loading');
    let $accountResult = $('.js-account-list__result');

    /* cart */
    let $initialCartLoading = $('.js-cart__list-loading');
    let $initialCartResult = $('.js-cart__list-result');
    let $initialCartCount = $('.js-num-of-products');

    if (($accountResult && $accountResult.length) || ($initialCartResult && $initialCartResult.length) && ($initialCartCount && $initialCartCount.length)) {
        let url = _config.headerInfoUrl;

        $accountLoading.attr('hidden', null);
        $accountResult.attr('hidden', 'hidden');

        $initialCartLoading.attr('hidden', null);
        $initialCartResult.attr('hidden', 'hidden');

        let request = fetch(url, {
            method: 'get',
        });

        showNotification(request);

        request.then(response => response.json())
            .then(result => {
                if (result.success) {
                    if (result.accountDropIn) {
                        setContent($accountResult, $accountLoading, result.accountDropIn);
                    }
                    if (result.listContent) {
                        setContent($initialCartResult, $initialCartLoading, result.listContent);
                    }

                    //upselling modal
                    if(result.showUpsellingModal){
                        let $modal = $(`#${result.upsellingModalId}`).first();
                        let $modalContent = $modal.find('.js-ajax-modal-content');
                        if ($modalContent.length) {
                            $modalContent.html(result.upsellingModalContent);
                            initModulesInScope($modalContent);
                        }
                        $modal.modal('show');
                    }

                    if (result.count > 0) {
                        $initialCartCount.text(result.count).attr('hidden', false);
                    }
                }
            }).catch(e => {
                console.error(e);
                $accountLoading.attr('hidden', 'hidden');
                $accountResult.attr('hidden', false);

                $initialCartLoading.attr('hidden', 'hidden');
                $initialCartResult.attr('hidden', false);
            });
    }
}

function setContent(result, loading, content) {
    if (content) {
        result.empty().append(content);
        loading.attr('hidden', 'hidden');
        result.attr('hidden', null);

        initModulesInScope(result);
    } else {
        loading.attr('hidden', 'hidden');
        result.attr('hidden', 'hidden');
    }
}