import onInitModulesInScope from "./onInitModulesInScope";

import 'popper.js';
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';

import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
import 'bootstrap/js/dist/tooltip';
import * as ajaxInclude from './ajaxInclude';
import * as goToLink from '@elements/go-to-link';
import * as copyToClipboard from './copyToClipboard';
import * as toc from './toc';
import * as skipLinks from './skip-links';
import * as lazyImg from '@elements/lazy-img';
import * as playVideo from './playVideo';
import * as registrationTracking from './registrationTracking';
import * as navTracking from './navTracking';
import * as scrollTop from './scrollTop';
import * as trustBadge from './trustBadge';
import * as ajaxModal from './ajaxModal';
import * as redirectUrl from './redirectUrl';
import * as affix from '@elements/affix';
import * as reloadForm from '@elements/reload-form';

import {init as notificationInit, PLACEMENT_TYPES} from '@elements/alert-notification';
import {translate} from '@elements/translations';
import * as typeahead from '@elements/typeahead';
import * as header from './header';
import * as nav from '@elements/accessibility-nav';
import * as touchNav from './touchNav';
import * as navToggle from './nav-toggle';
import * as toggleNavLevel from './toggle-navlevel';
import * as cart from './cart';
import * as initialHeaderInfo from './initialHeaderInfo';
import * as menuAim from './menuAim';
import * as tracking from "@elements/tracking";
import * as renderTemplate from './renderTemplate';
import * as newsletterCheckboxes from './newsletterCheckboxes';
import * as loyaltyMembershipConsent from './loyaltyMembershipConsent';
import * as showCollapseIfChecked from './showCollapseIfChecked';
import * as newsletterSubmit from './newsletterSubmit';
import * as parsley from '@elements/parsley-bootstrap-validation';
import * as wysiwygTables from './wysiwygTables';
import * as matchmediaViewer from "./matchmedia-viewer";
import * as scrollIndicator from './scrollIndicator';
import * as teaserColorHover from './teaserColorHover';
import * as openModal from './openModal';
import * as openNewsletterModal from './openNewsletterModal';
import * as plzValidation from './plzValidation';
import * as lazyIframe from '@elements/lazy-iframe';
import * as ieCheck from './ieCheck';
import * as targeting from './targeting';
import * as modal from './modal';
import * as fileInput from './fileInputCustom';
import * as hrefButton from './hrefButton';
import * as hotspots from './hotspots';
import * as uspFlyout from './uspFlyout';
import * as showCollapseSelect from './showCollapseSelect';
import * as inputDisablePaste from './inputDisablePaste';
import initModulesInScope from "@elements/init-modules-in-scope";

let defaultOptions = {
    key: '__alertNotification',
    errorText: translate('alert-notification.generic-error'),
    closeText: translate('alert-notification.close'),
};
function mergeOptions(defaultOptions, options) {
    let mergedOptions = {...defaultOptions, ...options};

    if (options && options.$container && options.$container.length && options.$container !== defaultOptions.$container) {
        mergedOptions.placement = PLACEMENT_TYPES.CUSTOM;
    } else {
        mergedOptions.$container = defaultOptions.$container;
        mergedOptions.placement = defaultOptions.placement;
    }

    return mergedOptions;
}
notificationInit({
    renderFunction: function defaultRender({type = 'info', title, content, closable = true, styleModifier = '', placement} = {}, options) {
        options = mergeOptions(defaultOptions, options);

        if (content || title) {
            options.$container.attr('hidden', null);

            let result = options.$container.append(
                `<div class="alert alert-${type} alert-dismissible fade show ${styleModifier}" role="alert">
                ${title ? (
                    `<h4 class="alert-heading">
                        ${title}
                    </h4>`
                ) : ''}
                
                ${closable ? (
                    `<button type="button" 
                             class="close" 
                             data-dismiss="alert" 
                             aria-label="${options.closeText}"  
                             title="${options.closeText}">
                            <span aria-hidden="true">&times;</span>
                        </button>`
                ) : ''} 
                ${content ? (
                    `<div>
                        ${content}               
                    </div>`
                ) : ''}
            </div>`
            );
            initModulesInScope(result);
            return result;
        }
    }
});

$('#countryModal').on('hidden.bs.modal', function (e) {
    let $toggleBtn = $('.js-country-modal__toggle');

    /* remove modal toggle */
    $toggleBtn.removeAttr('data-toggle').removeAttr('data-target');

    /* add href */
    $toggleBtn.attr('href',$toggleBtn.attr('data-href'));
})

$(function () {
    $('[data-toggle="popover"]').popover();
    $('body').tooltip({
        selector: '[data-toggle=tooltip]'
    });
});

$(function () {
    $('[data-toggle="tooltip"]').filter(function(idx) {
        // don't init tooltips with "js-tooltip--prevent-on-mobile" on mobile
        return ! ($(this).hasClass('js-tooltip--prevent-on-mobile') && matchMedia('(max-width: 767px)').matches);
    }).tooltip()
});

onInitModulesInScope(ajaxInclude.initInScope);

goToLink.init();

onInitModulesInScope(copyToClipboard.initInScope);

if ($('.js-toc__list').length) {
    toc.init();
    onInitModulesInScope(toc.initInScope);
}

if ($('.js-redirect-url').length) {
    toc.init();
    onInitModulesInScope(redirectUrl.initInScope);
}

/* scroll to end of breadcrumbs */
let breadcrumb = document.getElementsByClassName("breadcrumb");
if(breadcrumb.length > 0) {
    breadcrumb[0].scrollLeft = breadcrumb[0].scrollWidth;
}
onInitModulesInScope(skipLinks.initInScope);

onInitModulesInScope(lazyImg.createInitInScope({
    base: '.js-lazy-img',
    backgroundModifier: '.js-lazy-img--bg'
}, {preventNative: true}));

// import * as videoJs from './videoJs';
// onInitModulesInScope(videoJs.initInScope);
onInitModulesInScope(playVideo.initInScope);

onInitModulesInScope(registrationTracking.initInScope);

onInitModulesInScope(navTracking.initInScope);

onInitModulesInScope(scrollTop.initInScope);

onInitModulesInScope(trustBadge.initInScope);

onInitModulesInScope(ajaxModal.initInScope);

onInitModulesInScope(affix.initInScope);

onInitModulesInScope(reloadForm.initInScope);

let typeaheadOptions = {
    hint: false,
    highlight: true,
    minLength: 2,
    autocompleteText: 'title'
};

const layouts = {
    headerLayout : (data, category) => {
        if (category.name === 'searchButton') {

        } else {
            return ('<div class="typeahead-heading d-flex justify-content-between">'+
                '<a href="' + data.overviewUrl +'" class="typeahead-heading__text mb0">' +
                '<span>' + category.title + '</span></a>' +
                '</div>')
        }
    },
    suggestionLayout : (data, category) => {

        const printImage = (imgUrl) => {
            return (
                '<div>' +
                '<img style="min-height: 90px; min-width: 90px; max-height: 90px; max-width: 90px" src="' + data.imgurl + '" alt="' + data.title + '"/>' +
                '</div>'
            )
        };

        if (category.name === 'product') {
            return (
                '<div class="d-flex tt-suggestion--product">' +
                (data.image ?
                    '<div class="tt-suggestion__product-image embed-responsive embed-responsive-1by1">' +
                    data.image +
                    '</div>'
                    : '') +
                '<div class="ml-3 d-flex flex-column justify-content-between ">' +
                '<div class="">' +
                '<p class="mb-0">' + data.title + '</p>' +
                '<small>' + data.text + '</small>' +
                '</div>' +
                '<div class="">' +
                (data.oldprice ? '<s class="text-muted">' + data.oldprice + '</s>' : '') +
                (data.oldprice ? '<span class="text-danger ml-2 font-family-bold">' + data.price + '</span>' : '<span>' + data.price + '</span>') +
                '</div>' +
                '</div>' +
                '</div>'
            )
        } else if (category.name === 'searchButton') {
            let searchUrl = data.searchUrl
                + (data.searchUrl.indexOf('?') >= 0 ? '&' : '?' )
                + 'q=' + data._query;

            //escape searchUrl
            searchUrl = searchUrl.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');

            return  `<div class="tt-suggestion--search-button">
                        <a href="${ searchUrl }" class="font-bold"><span>${ category.title }</span><span class="icon icon-arrow-right-bold ml-2 icon-in-text icon-in-text--sm" aria-hidden="true"></span></a>
                    </div>`;
        } else {
            return (
                '<div class="d-flex align-items-center"><div class="typeahead-suggestion">' + data.title + '</div>' +
                '<div class="text-light-grey ml-auto">' + data.text + '</div></div>'
            )
        }
    },
    errorLayout : () => {

    }
};

onInitModulesInScope(typeahead.createInitInScope(typeaheadOptions , layouts));

onInitModulesInScope(header.initInScope);

onInitModulesInScope(nav.createInitInScope('.js-touch-nav', '.js-touch-nav__item'));

onInitModulesInScope(touchNav.initInScope);

navToggle.init();

onInitModulesInScope(toggleNavLevel.initInScope);

initialHeaderInfo.init();

onInitModulesInScope(cart.initInScope);

onInitModulesInScope(menuAim.initInScope);

tracking.initGtmEventTracking({debug: true}); // for GTM tracking
onInitModulesInScope(renderTemplate.initInScope);
// app.renderAllTemplates = renderTemplate.renderAllTemplates;
onInitModulesInScope(newsletterCheckboxes.initInScope);

onInitModulesInScope(showCollapseIfChecked.initInScope);

onInitModulesInScope(newsletterSubmit.initInScope);

onInitModulesInScope(parsley.initInScope);

onInitModulesInScope(wysiwygTables.initInScope);

onInitModulesInScope(plzValidation.initInScope);

matchmediaViewer.init();
onInitModulesInScope(matchmediaViewer.initInScope);

onInitModulesInScope(scrollIndicator.initInScope);

onInitModulesInScope(teaserColorHover.initInScope);

onInitModulesInScope(showCollapseIfChecked.initInScope);

onInitModulesInScope(showCollapseSelect.initInScope);

onInitModulesInScope(inputDisablePaste.initInScope);

openModal.init();

openNewsletterModal.init();

onInitModulesInScope(lazyIframe.createInitInScope(undefined, {offset: window.innerHeight / 4}));

onInitModulesInScope(fileInput.initInScope);

onInitModulesInScope(hrefButton.initInScope);

onInitModulesInScope(hotspots.initInScope);

onInitModulesInScope(uspFlyout.initInScope);

onInitModulesInScope(ieCheck.initInScope);

onInitModulesInScope(targeting.init);

onInitModulesInScope(loyaltyMembershipConsent.initInScope);

modal.init();
